
















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {ValidatorRules} from "@/entities/ingredient-additive-entity";

import { help as PopoverText } from '@/lang/help/ingredient-create';

import IngredientDisplaySetting from "@/entities/ingredient-display-setting";

import AdditiveRepository, {
  TAdditiveSynonymListItem
} from '@/repositories/master/additive-repository';
import IngredientAdditiveEntity from "@/entities/ingredient-additive-entity";

import AmountSummaryRow from './AmountSummaryRow.vue';
import AdditiveSelection from "@/components/Project/Additive/AdditiveSelection.vue";
import AllergenSelectionMultiple from "@/components/Project/Allergen/AllergenSelectionMultiple.vue";
import AdditivePurposeSelection from "@/components/Project/Additive/AdditivePurposeSelection.vue";
import IngredientEntity from "@/entities/ingredient-entity";

@Component({
  components: {
    AdditivePurposeSelection,
    AllergenSelectionMultiple,
    AdditiveSelection,
    AmountSummaryRow
  }
})
export default class extends Vue {
  @Prop({required: true}) private ingredient!: IngredientEntity;
  @Prop({required: true}) private ingredientAdditives!:IngredientAdditiveEntity[];
  @Prop({required: true}) private ingredientDisplaySetting!:IngredientDisplaySetting;
  @Prop({required: true}) private amountSum!:number;
  @Prop({default: false}) private disabled!:boolean;

  public additiveSynonymList:TAdditiveSynonymListItem[] = [];

  private readonly PopoverText = PopoverText;

  private get rules() {
    return ValidatorRules;
  }

  private getAdditiveRule(ingredientAdditive:IngredientAdditiveEntity) {
    return ingredientAdditive.isEmpty ? null : ValidatorRules.additiveSynonymId;
  }
  private getAmountRule(ingredientAdditive:IngredientAdditiveEntity) {
    return ingredientAdditive.isEmpty ? null : this.rules.amount;
  }

  private async created() {
    this.additiveSynonymList = await new AdditiveRepository().listForLabel();
    this.init();
  }

  public init() {
    if (!this.ingredientAdditives.length) {
      this.addAdditiveRow();
    }
  }

  // region Additive
  private addAdditiveRow() {
    this.ingredientAdditives.push(new IngredientAdditiveEntity({}, this.ingredient));
  }
  private deleteAdditiveRow(index: number) {
    if (this.disabled) return;

    this.ingredientAdditives.splice(index, 1);

    if (this.ingredientAdditives.length < 1) {
      this.addAdditiveRow();
    }
  }

  private async onSelectedAdditiveChanged(selectedItem:TAdditiveSynonymListItem, ia:IngredientAdditiveEntity) {
    ia.$loading = true;
    try {
      ia.additive = await (new AdditiveRepository).findById(selectedItem.additiveId);
      ia.additivePurposeSynonymId = ia.additive.getDefaultAdditivePurposeSynonymId();
    } finally {
      ia.$loading = false;
    }
  }

  private row(scope: { row: IngredientAdditiveEntity }): IngredientAdditiveEntity {
    return scope.row;
  }

  private getAdditiveSelectionAlert(ia:IngredientAdditiveEntity) {
    if (!ia.additive) return '';
    if (ia.additive.isTypePurposeHeiki) {
      return PopoverText.invalidAdditive;
    } else if (ia.additive.isPurposeManufactureAgentType) {
      return `表示の事故防止のため、添加物用途「${ia.additive.name}」は選択できなくなりました。<br><br>具体的な添加物物質名を選択してください。`;
    }
    return '';
  }

  // endregion
}
